import { DriverNotification } from '../entities';
import { DriverNotificationResponse } from '../types';

export const mapDriverNotificationApiResponse = (
  response: DriverNotificationResponse,
): DriverNotification => {
  return new DriverNotification(
    response.id,
    response.attributes.type,
    response.attributes.createdAt,
    response.attributes.readStatus,
    response.attributes.updatedAt,
    response.attributes.payload,
  );
};
