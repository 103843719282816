import { MultiPluginFactory } from '@/features/core/plugin';
import { driverNotificationsApiPlugin } from './api';
import {
  driverNotificationsRegistryPlugin,
  driverNotificationsServicePlugin,
} from './services';
import { driverNotificationEntityPlugin } from './entities';

export * from './api';
export * from './services';
export * from './types';
export * from './entities';

export const driverNotificationsPlugin = MultiPluginFactory.with({
  driverNotificationsRegistryPlugin,
  driverNotificationsApiPlugin,
  driverNotificationsServicePlugin,
  driverNotificationEntityPlugin,
});
