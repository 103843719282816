import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { TourDriverNotificationPayload } from '../types';

export class NewDriverNotification
  extends RestorableEntity
  implements NewEntity
{
  type = 'driverNotification';
}

export class DriverNotification
  extends NewDriverNotification
  implements Entity
{
  constructor(
    public id: string,
    public notificationType: string,
    public createdAt: string,
    public readStatus: boolean,
    public updatedAt: string,
    public payload: TourDriverNotificationPayload,
  ) {
    super();
  }
}

export class DriverNotificationStorageSetup implements StorageSetupStrategy {
  async setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.DriverNotification_1).stores({
      [`${storeName}`]: '&id, type, payload',
    });
    version(DBVersions.DriverNotification_2).stores({
      [`${storeName}`]: '&id, notificationType, payload',
    });
    return Promise.resolve();
  }
}
