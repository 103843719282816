import { ApiClient, Response } from '@/features/core/api';
import { DriverNotificationResponse } from '../types';
import {
  GetDriverNotificationsError,
  MarkDriverNotificationAsReadError,
} from '../error';
import { DriverNotificationsApiClientInterface } from './driver-notification-api-client-interface';
import { DriverNotification } from '../entities';
import { mapDriverNotificationApiResponse } from '../services/driver-notification-mapper';

export class DriverNotificationsApiClient
  implements DriverNotificationsApiClientInterface
{
  constructor(private api: ApiClient) {}

  public async getNewDriverNotifications(): Promise<DriverNotification[]> {
    const driverNotificationsApiResponse = await this.api.client.get<
      Response<DriverNotificationResponse[]>
    >('/driver-notifications?filter[notification.readStatus]=false', {
      innerErrorCode: GetDriverNotificationsError.Code,
    });

    return driverNotificationsApiResponse.data.data.map<DriverNotification>(
      (driverNotificationResponse) =>
        mapDriverNotificationApiResponse(driverNotificationResponse),
    );
  }

  public async markDriverNotificationsAsRead(ids: string[]): Promise<void> {
    await this.api.client.patch(
      '/driver-notifications',
      {
        data: ids.map((id: string) => ({
          type: 'driver-notifications',
          id: parseInt(id),
          attributes: {
            readStatus: true,
          },
        })),
      },
      {
        innerErrorCode: MarkDriverNotificationAsReadError.Code,
      },
    );
  }
}
