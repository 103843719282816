import { RouteLocationNormalized } from 'vue-router';
import { router } from '@/features/core/router';
import {
  ProcessedOrder,
  ProcessedOrderServiceInterface,
} from '@/features/processed-order';
import { EntityRepository } from '@/features/core/entity-repository';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import {
  Order,
  OrderEventNames,
  OrderLocalStatus,
  ordersServicePlugin,
} from '@/features/orders';
import { AuthService } from '@/features/core/auth';
import { LoggerService } from '@/features/core/logger';
import { $t } from '@/i18n';
import { AppCrash, AppCrashMeta, AppCrashServiceConfig } from '../types';

export class AppCrashService implements AppCrash {
  constructor(
    private options: AppCrashServiceConfig,
    private entityRepository: EntityRepository,
    private authService: AuthService,
    private processedOrderService: ProcessedOrderServiceInterface,
    private loggerService: LoggerService,
  ) {}

  private async trackLastRoute(to: RouteLocationNormalized): Promise<void> {
    const routeAppCrashMeta = to.meta?.appCrashFeature as AppCrashMeta;
    const shouldTrackRoute = routeAppCrashMeta?.trackRoute;
    const orderId = (to.params?.id || to.query?.['order-id']) as string;
    const routePath = to.fullPath;
    if (!shouldTrackRoute || !orderId) {
      return;
    }
    const processedOrder = await this.processedOrderService.getById(orderId);
    if (processedOrder) {
      await this.updateLastRoute(
        routePath,
        orderId,
        processedOrder.processedBy,
      );
    }
  }

  async updateProcessedOrderData(order: Order): Promise<void> {
    switch (order.localStatus) {
      case OrderLocalStatus.PickingInProgress:
      case OrderLocalStatus.HandoverInProgress:
        await this.addProcessedOrder(order.id);
        break;

      case OrderLocalStatus.PickingCompleted:
      case OrderLocalStatus.PickingCancelled:
      case OrderLocalStatus.HandoverCancelled:
      case OrderLocalStatus.HandoverCompleted:
        await this.removeProcessedOrder(order.id);
        break;
    }
  }

  setupNavigationGuard(): void {
    router.get().afterEach(async (to, from, failure) => {
      if (!failure) {
        await this.trackLastRoute(to);
      }
    });
  }

  private async updateLastRoute(
    routePath: string,
    orderId: string,
    processedBy: string,
  ): Promise<void> {
    await this.processedOrderService.setProcessedOrder({
      id: orderId,
      lastRoute: routePath,
      processedBy,
    });
  }

  private async addProcessedOrder(
    orderId: string,
    processedBy?: string,
  ): Promise<void> {
    const processedByUserEmail = processedBy
      ? processedBy
      : await this.authService.getUserEmail();

    await this.processedOrderService.setProcessedOrder({
      id: orderId,
      processedBy: processedByUserEmail,
    });
  }

  private async removeProcessedOrder(orderId: string): Promise<void> {
    await this.processedOrderService.removeById(orderId);
  }

  private isOrderPaused(order: Order | undefined): boolean {
    return Boolean(order && order.localStatus === OrderLocalStatus.Paused);
  }

  private async getCrashedOrder(
    processedOrders: ProcessedOrder[] | undefined,
  ): Promise<ProcessedOrder | void> {
    if (processedOrders && Array.isArray(processedOrders)) {
      for (let index = 0; index < processedOrders.length; index++) {
        const order = await this.entityRepository.getById(Order, {
          id: processedOrders[index].id,
        });
        if (!this.isOrderPaused(order.value)) {
          return processedOrders[index];
        }
      }
    }
  }

  async checkUnfinishedProcessAndRedirect(): Promise<boolean> {
    if (this.options.skipAppCrashRedirect) {
      return false;
    }
    const processedByUserEmail = await this.authService.getUserEmail();
    if (!processedByUserEmail) {
      return false;
    }
    const processedOrders = await this.processedOrderService.getAll({
      filter: {
        processedBy: {
          equals: processedByUserEmail,
        },
      },
    });

    const crashedOrder = await this.getCrashedOrder(processedOrders);

    if (crashedOrder && crashedOrder.lastRoute) {
      const order = await ordersServicePlugin
        .get()
        .getOrderById(crashedOrder.id);

      if (order === null) {
        this.loggerService.fatal(
          new Error(
            $t('errors.app-crash.order-recovered-missing-order.text', {
              crashedOrderId: crashedOrder.id,
            }),
          ),
        );

        // Processed order is removed to fix inconsistent behavior and prevent bugs - @see CICS-56187
        await this.processedOrderService.removeById(crashedOrder.id);

        return false;
      }

      this.loggerService.fatal(
        new Error(
          $t('errors.app-crash.order-recovered-after-crash.text', {
            crashedOrderId: crashedOrder.id,
          }),
        ),
        {
          orderId: crashedOrder.id,
          lastRoute: crashedOrder.lastRoute,
        },
      );

      await ordersServicePlugin
        .get()
        .trackEvent(order.value, OrderEventNames.picking_resumed, {
          allowMultipleTracking: true,
        });
      await router.get().push(crashedOrder.lastRoute);
      notificationPlugin.get().show({
        text: $t('components.app-crash.order-restored-notification.text'),
        type: NotificationType.Success,
      });
      return true;
    }
    return false;
  }
}
