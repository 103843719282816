import { ExecPluginFactory } from '@/features/core/plugin';
import { storageSetupPlugin } from '@/features/core/storage';
import {
  DriverNotification,
  DriverNotificationStorageSetup,
} from './tour-driver-notification';

export * from './tour-driver-notification';
export const driverNotificationEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin
    .get()
    .add(DriverNotification, new DriverNotificationStorageSetup());
});
