import { computed, Ref, ref } from 'vue';
import { DepositOption } from '@/features/handover/types';
import { DepositTypes } from '@/features/handover/types/enums';
import { configurationServicePlugin } from '@/features/configuration';
import { useHandoverOrder } from '@/features/handover/composables/useHandoverOrder';
import { Order, OrderItem, ordersServicePlugin } from '@/features/orders';
import { UseDeposit } from '@/features/handover/types/UseDeposit';
import { dropsHandoverServicePlugin } from '@/features/tour-drops';
import { debounce } from '@/utils/helpers/debounce';
import { deepClone } from '@/utils/helpers/deepClone';

import defaultBottleImage from '@/assets/images/default-bottle-deposit.svg';
import germanQuarterBottleImage from '@/assets/images/25-cent-bottle.svg';
import { i18n } from '@/i18n';
import emptyImage from '@/assets/images/empty.svg';

const deposits: Ref<Map<string, Array<DepositOption>>> = ref(new Map());
const loading: Ref<boolean> = ref(false);

const depositImages: Record<
  string,
  Record<string, { path: string; e2e: string }>
> = {
  'de-DE': {
    deposit_25: {
      path: germanQuarterBottleImage,
      e2e: 'de-25-ct-bottle-Deposit',
    },
  },
};

const defaultBottleImageE2E = 'default-bottle-Deposit';
const saveOrderDebounceTimeout = 1000;
const isDepositEnabled = ref(false);

export const useDeposit = (): UseDeposit => {
  // construct Map for storing depositOptions
  const loadDepositOptions = async () => {
    isDepositEnabled.value = (
      await configurationServicePlugin.get().isFeatureActive('bottleDeposit')
    ).value;
    loading.value = true;
    for (const depositTypeName of Object.values(DepositTypes)) {
      const factory = depositOptionProviderFactory(depositTypeName);
      deposits.value.set(depositTypeName, await factory.getDepositOptions());
    }
    loading.value = false;
  };

  const getDepositOption = (
    depositType: string,
    depositOptionId: string,
  ): DepositOption | undefined => {
    const depositOptions = deposits.value.get(depositType);
    return depositOptions?.find((element) => element.id === depositOptionId);
  };

  const getDepositOptions = (depositType: string): DepositOption[] => {
    return deposits.value.get(depositType) ?? [];
  };

  const increase = (depositType: string, depositOptionId: string) => {
    const depositOption = getDepositOption(depositType, depositOptionId);
    if (!depositOption) return;
    if (depositOption.quantity >= depositOption.limit) return;
    depositOption.quantity = depositOption.quantity + 1;
    assignToOrder(depositType, depositOptionId);
  };

  const decrease = (depositType: string, depositOptionId: string) => {
    const depositOption = getDepositOption(depositType, depositOptionId);
    if (!depositOption) return;
    if (depositOption.quantity <= 0) return;
    depositOption.quantity = depositOption.quantity - 1;
    assignToOrder(depositType, depositOptionId);
  };

  const onUpdate = (
    quantity: number,
    depositType: string,
    depositOptionId: string,
  ) => {
    const option = getDepositOption(depositType, depositOptionId);
    if (!option) return;
    option.quantity = quantity;
    assignToOrder(depositType, depositOptionId);
  };

  const saveOrder = async (): Promise<void> => {
    const { order } = useHandoverOrder();
    if (!order.value) return;
    await ordersServicePlugin.get().saveOrder(order.value);
  };

  const debouncedSaveOrder = debounce(saveOrder, saveOrderDebounceTimeout);

  const assignToOrder = (
    depositType: string,
    depositOptionId: string,
  ): void => {
    const depositOption = deepClone(
      getDepositOption(depositType, depositOptionId),
    );
    const { order } = useHandoverOrder();
    if (!order.value || !depositOption) return;
    if (depositOptionExceedsLimit(depositOption)) return;

    createDepositInOrderIfNotExists(order, depositType);

    const depositTypeIndexInOrder = Object.keys(order.value).findIndex(
      (value) => value === depositType,
    );

    const depositOptions = Object.values(order.value)[
      depositTypeIndexInOrder
    ] as DepositOption[];

    const depositInOrderIndex = (depositOptions ?? []).findIndex(
      (item: { id: string }) => item.id === depositOption.id,
    );

    if (depositInOrderIndex >= 0) {
      depositOptions[depositInOrderIndex] = depositOption;
    } else {
      depositOptions.push(depositOption);
    }

    debouncedSaveOrder();
  };

  const createDepositInOrderIfNotExists = (
    order: Ref<Order | null>,
    depositType: string,
  ) => {
    if (!order.value) return;
    if (!Object.prototype.hasOwnProperty.call(order.value, depositType)) {
      order.value = Object.assign(order.value, { [depositType]: [] });
    }
  };

  const assignToOrderAll = (): void => {
    const { order } = useHandoverOrder();
    if (!order.value) return;

    Object.values(DepositTypes).forEach((depositType: string) =>
      getDepositOptions(depositType)
        .filter(({ quantity }) => quantity > 0)
        .forEach(({ id }) => assignToOrder(depositType, id)),
    );
  };

  const depositOptionExceedsLimit = ({
    quantity,
    limit,
  }: DepositOption): boolean => quantity < 0 || quantity > limit;

  const depositExceedsLimit = computed(() =>
    Object.values(DepositTypes).some((depositType) =>
      deposits.value.get(depositType)?.some(depositOptionExceedsLimit),
    ),
  );

  return {
    loading,
    getDepositOptions,
    getDepositOptionFromOrder,
    increase,
    decrease,
    loadDepositOptions,
    onUpdate,
    assignToOrder,
    assignToOrderAll,
    depositExceedsLimit,
    deposits,
    isDepositEnabled,
  };
};

const getDepositOptionFromOrder = (
  depositType: string,
  depositOptionId: string,
): DepositOption | undefined => {
  const { order } = useHandoverOrder();
  return (order.value?.[depositType as keyof Order] as DepositOption[])?.find(
    (depositOption) => depositOption.id === depositOptionId,
  );
};

const depositOptionProviderFactory = (
  depositType: string,
): DepositOptionProvider => {
  switch (depositType) {
    case DepositTypes.bottleDeposit:
      return BottleDepositOptionProvider();
    case DepositTypes.exchange:
      return ExchangeDepositOptionProvider();
  }
  throw new Error(depositType + ' not found');
};

interface DepositOptionProvider {
  getDepositOptions(): Promise<Array<DepositOption>>;
}

const BottleDepositOptionProvider = (): DepositOptionProvider => {
  const getDepositOptions = async (): Promise<Array<DepositOption>> => {
    if (!isDepositEnabled.value) return [];

    const depositOptionsValue = await configurationServicePlugin
      .get()
      .getFeatureOption('bottleDeposit', 'depositOptions', 'array');

    return depositOptionsValue?.map((option): DepositOption => {
      const [key, label, limit] = option.split(':');
      const locale = i18n.global.locale.value;
      const quantity =
        getDepositOptionFromOrder(DepositTypes.bottleDeposit, key)?.quantity ??
        0;

      return {
        id: key,
        limit: Number(limit),
        quantity,
        product: {
          id: key,
          image: depositImages[locale]?.[key]?.path ?? defaultBottleImage,
          imageE2E: depositImages[locale]?.[key]?.e2e ?? defaultBottleImageE2E,
          productName: label.endsWith(';') ? label.replace(';', '') : label,
        },
      };
    });
  };

  return {
    getDepositOptions,
  };
};

const ExchangeDepositOptionProvider = (): DepositOptionProvider => {
  const getDepositOptions = async (): Promise<Array<DepositOption>> => {
    const exchangeableOrderItems = await dropsHandoverServicePlugin
      .get()
      .getExchangeableOrderItems();
    return exchangeableOrderItems.map((orderItem: OrderItem): DepositOption => {
      const quantity =
        getDepositOptionFromOrder(DepositTypes.exchange, orderItem.id)
          ?.quantity ?? 0;

      return {
        id: orderItem.id,
        limit: orderItem.quantity,
        quantity,
        product: {
          id: orderItem.id,
          image: orderItem.product.image ?? emptyImage,
          productName: orderItem.product.productName,
          imageE2E: defaultBottleImageE2E,
        },
      };
    });
  };

  return {
    getDepositOptions,
  };
};
