import { DriverNotificationsProcessor } from '@/features/driver-notifications';
import { DriverNotification } from '@/features/driver-notifications';
import { ToursService } from './tours-service';

export class ToursNotificationProcessor
  implements DriverNotificationsProcessor
{
  typeResponsibilities = ['new_tour_assignment', 'tour_assignment_removed'];

  constructor(
    private toursService: ToursService,
    private updateTourAssignmentInfo: () => Promise<void>,
  ) {}

  async processDriverNotification(
    notification: DriverNotification,
  ): Promise<void> {
    if (notification.notificationType === 'new_tour_assignment') {
      await this.toursService.addNewTour(notification.payload.tourReference);
    }
    if (notification.notificationType === 'tour_assignment_removed') {
      await this.toursService.removeExistingTour(
        notification.payload.tourReference,
      );
    }
    await this.updateTourAssignmentInfo();
  }
}
