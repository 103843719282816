import { Order } from '../entities';
import {
  OrderAction,
  OrderActionStatus,
  OrderLocalStatus,
  OrderRaw,
} from '../types';

export const actionToLocalStatus: Record<string, OrderLocalStatus> = {
  [OrderActionStatus.picking]: OrderLocalStatus.PickingReady,
  [OrderActionStatus.picking_completed]: OrderLocalStatus.PickingInProgress,
  [OrderActionStatus.handover]: OrderLocalStatus.HandoverReady,
  [OrderActionStatus.handover_completed]: OrderLocalStatus.HandoverInProgress,
};

export function mergeOrderStatus(
  actions: OrderAction[],
  existingOrder?: Order | OrderRaw,
): OrderLocalStatus {
  const matchedAction = actions.find(
    (action) => action.targetStatus in actionToLocalStatus,
  );

  if (matchedAction && existingOrder?.localStatus !== OrderLocalStatus.Paused) {
    return actionToLocalStatus[matchedAction.targetStatus];
  }

  return existingOrder?.localStatus ?? OrderLocalStatus.PickingReady;
}

export function hasOrderLocalStatus(
  order: Order | OrderRaw,
  ...statuses: OrderLocalStatus[]
): boolean {
  return statuses.some((status) => status === order.localStatus);
}

export function isOrderPicking(order: Order | OrderRaw): boolean {
  return hasOrderLocalStatus(
    order,
    OrderLocalStatus.PickingReady,
    OrderLocalStatus.PickingInProgress,
    OrderLocalStatus.PickingCompleted,
    OrderLocalStatus.Paused,
    OrderLocalStatus.PickingCancelled,
  );
}

export function isOrderHandover(order: Order | OrderRaw): boolean {
  return hasOrderLocalStatus(
    order,
    OrderLocalStatus.HandoverReady,
    OrderLocalStatus.HandoverInProgress,
    OrderLocalStatus.HandoverCompleted,
    OrderLocalStatus.HandoverCancelled,
  );
}
