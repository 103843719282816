import { DriverNotification } from '@/features/driver-notifications';
import { computed, ref, Ref } from 'vue';
import { storagePlugin } from '@/features/core/storage';
import { TourAssignmentInfo } from '../types';
import { $t } from '@/i18n';
import { ordersServicePlugin } from '@/features/orders';
import { configurationServicePlugin } from '@/features/configuration';

const driverNotifications: Ref<DriverNotification[]> = ref([]);

export function useTourAssignmentInfo(): TourAssignmentInfo {
  const updateTourAssignmentInfo = async () => {
    driverNotifications.value = await storagePlugin
      .get()
      .getAll(DriverNotification);

    const pickingOrderDependencyActive = await configurationServicePlugin
      .get()
      .getFeatureOption(
        'driverApp',
        'pickingOrdersDependencyActive',
        'boolean',
      );

    if (!pickingOrderDependencyActive) {
      await ordersServicePlugin.get().fetchAllOrders();
    }
  };

  const notificationLabel = computed(() => {
    const newNotificationsCount = driverNotifications.value.filter(
      (notification: DriverNotification) =>
        notification.notificationType === 'new_tour_assignment',
    ).length;
    const removedNotificationsCount = driverNotifications.value.filter(
      (notification: DriverNotification) =>
        notification.notificationType === 'tour_assignment_removed',
    ).length;
    const labelParts = [];
    if (newNotificationsCount > 0) {
      labelParts.push(
        $t('components.tours-list.driver-notifications.new-tour-assignment', {
          count: newNotificationsCount,
        }),
      );
    }
    if (removedNotificationsCount > 0) {
      labelParts.push(
        $t('components.tours-list.driver-notifications.tour-removed', {
          count: removedNotificationsCount,
        }),
      );
    }
    return labelParts.join(', ');
  });

  const hasNotification = computed((): boolean => {
    return driverNotifications.value.length > 0;
  });

  const clearNotifications = async () => {
    await storagePlugin.get().removeAll(DriverNotification);
    driverNotifications.value = [];
  };

  return {
    updateTourAssignmentInfo,
    clearNotifications,
    hasNotification,
    notificationLabel,
  };
}
