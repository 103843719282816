import { EntitySyncOperation, SyncStatus } from '../types';
import { Cancellable } from '@/utils/types';
import { Sync } from '../entities';
import { SyncSchedulerExecutorFilterDto } from './sync-scheduler-executor';

export const ENTITY_SYNC_TAG = 'ENTITY_SYNC_TAG';

export interface SyncScheduler {
  getPending(
    syncFilterDto?: SyncSchedulerExecutorFilterDto,
  ): Promise<SyncStatus[] | undefined>;

  getFailed(
    filterReportedTransmissions?: boolean,
  ): Promise<SyncStatus[] | undefined>;

  removeFailedTransmissions(): Promise<void>;

  getStatus(syncId: string): Promise<SyncStatus | undefined>;

  onStatusChange(
    syncId: string,
    callback: (syncStatus: Sync) => void,
  ): Cancellable;

  schedule(operation: EntitySyncOperation): Promise<SyncStatus | void>;
}
