import { ProviderPluginFactory } from '@/features/core/plugin';
import { DriverNotificationsService } from './driver-notifications-service';
import { driverNotificationsApiPlugin } from '../api';
import { DriverNotificationsRegistry } from './driver-notifications-registry';
import { authServicePlugin } from '@/features/core/auth';
import { storagePlugin } from '@/features/core/storage';

export * from './driver-notifications-service';
export * from './driver-notifications-registry';

export const driverNotificationsRegistryPlugin =
  ProviderPluginFactory.create<DriverNotificationsRegistry>({
    key: Symbol('DriverNotificationsRegistry'),
    defaultFactory: {
      create: () => new DriverNotificationsRegistry(),
    },
  });

export const driverNotificationsServicePlugin = ProviderPluginFactory.create({
  key: Symbol('DriverNotificationsService'),
  defaultFactory: {
    create: () =>
      new DriverNotificationsService(
        driverNotificationsApiPlugin.get(),
        driverNotificationsRegistryPlugin.get(),
        authServicePlugin.get(),
        storagePlugin.get(),
      ),
  },
});
0;
