import { computed, ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { TourDrop, UseDrops } from '@/features/tour-drops/types';
import { TourDropItem } from '../entities';
import {
  BottleDepositReturn,
  DropActions,
  DropStatus,
  RejectedDeliveryItem,
} from '@/features/tour-drops/types';
import { navigateToMap } from '@/features/ui/composables';
import router from '@/features/core/router';
import { useTours } from '@/features/tours';
import {
  dropsHandoverServicePlugin,
  dropsServicePlugin,
  dropsSortingServicePlugin,
  pickingOrderServicePlugin,
} from '@/features/tour-drops';
import { useHandoverOrder } from '@/features/handover/composables';
import { ExchangedItem, OrderLocalStatus } from '@/features/orders';
import { errorPlugin } from '@/features/core/errors';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { $t } from '@/i18n';
import { useNow } from '@vueuse/core';
import { formatDateTime, formatHours } from '@/composables/useDateFormat';
import { useFeatureToggle } from '@/features/feature-toggle';
import { configurationServicePlugin } from '@/features/configuration';
import NewCustomerPopupContent from '@/features/tour-drops/components/NewCustomerPopupContent.vue';

const drops: Ref<TourDrop[]> = ref([]);

const processing: Ref<boolean> = ref(false);
const recentlyDoneDrops: Ref<TourDrop[]> = ref([]);

const currentActiveDrop = ref<TourDrop | undefined | null>();
const isDropCanceled = ref(false);

const dropStatusOrder = <DropStatus[]>[
  DropStatus.open,
  DropStatus.delivery_in_progress,
  DropStatus.delivery_completed,
  DropStatus.picking_receptacles,
  DropStatus.picking_receptacles_completed,
  DropStatus.drop_paused,
  DropStatus.age_verification,
  DropStatus.age_rejected,
  DropStatus.handover_in_progress,
  DropStatus.drop_canceled,
  DropStatus.handover_completed,
  DropStatus.survey_in_progress,
  DropStatus.drop_completed,
];

export function useDrops(): UseDrops {
  const { currentTour } = useTours();
  const { confirm } = useDynamicDialog();
  const { order } = useHandoverOrder();
  const route = useRoute();
  const availableDrops = computed<TourDrop[]>(() =>
    drops.value?.filter(
      (drop) =>
        drop.status !== DropStatus.drop_completed &&
        drop.status !== DropStatus.drop_canceled &&
        drop.status !== DropStatus.drop_paused,
    ),
  );

  const activeDrops = computed(() =>
    drops.value.filter(
      (drop) =>
        drop.status !== DropStatus.open &&
        drop.status !== DropStatus.drop_canceled &&
        drop.status !== DropStatus.drop_paused &&
        drop.status !== DropStatus.drop_completed,
    ),
  );

  const pausedDrops = computed<TourDrop[]>(() =>
    drops.value.filter((drop) => drop.status === DropStatus.drop_paused),
  );

  const completedDrops = computed<TourDrop[]>(() => {
    return drops.value?.filter(
      (drop) =>
        drop.status === DropStatus.drop_completed ||
        drop.status === DropStatus.drop_canceled,
    );
  });

  const redirectToTourDrops = async () => {
    await router.get().push({
      name: 'tour-drops',
      params: {
        tourId: currentTour.value?.id,
      },
    });
  };

  const addToRecentlyDoneDrops = (newDrop: TourDrop) => {
    recentlyDoneDrops.value.push(newDrop);
  };

  const resetRecentlyDoneDrops = () => {
    recentlyDoneDrops.value = [];
  };

  const dropStatusToActionMap: { [key in DropStatus]?: DropActions } = {
    [DropStatus.open]: DropActions.start_drop_off,
    [DropStatus.delivery_in_progress]: DropActions.confirm_arrival,
    [DropStatus.delivery_completed]: DropActions.start_picking_receptacles,
    [DropStatus.picking_receptacles]: DropActions.start_picking_receptacles,
    [DropStatus.picking_receptacles_completed]: DropActions.start_handover,
    [DropStatus.start_handover]: DropActions.start_handover,
    [DropStatus.handover_in_progress]: DropActions.continue_handover,
    [DropStatus.survey_in_progress]: DropActions.start_survey,
  };

  const mapDropActions = (drops: TourDrop[]) =>
    drops.map((drop) => {
      if (dropStatusToActionMap[drop.status as DropStatus]) {
        return {
          ...drop,
          actions: [
            dropStatusToActionMap[drop.status as DropStatus] as DropActions,
          ],
        };
      } else {
        return drop;
      }
    });

  const loadDrops = async (): Promise<void> => {
    if (!currentTour.value) {
      return;
    }
    const filtering = {
      tourId: { equals: currentTour.value.id },
    };

    const dropItems = await entityRepositoryPlugin.get().getAll(TourDropItem, {
      filter: filtering,
    });

    drops.value = dropsSortingServicePlugin
      .get()
      .sortDrops(dropItems.value)
      .map(
        (dropItem, dropIndex): TourDrop => ({
          ...dropItem,
          dropIndex,
        }),
      );

    drops.value = mapDropActions(drops.value);
  };

  const setCurrentActiveDrop = (tourDrop?: TourDrop | null | undefined) => {
    currentActiveDrop.value = tourDrop;
  };

  const startDropOff = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;

    tourDrop.actions = [DropActions.confirm_arrival];

    await updateDropStatus(tourDrop, DropStatus.delivery_in_progress);
    await navigateToMap(tourDrop.address);
  };

  const confirmArrival = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;

    tourDrop.actions = [DropActions.start_picking_receptacles];
    await updateDropStatus(tourDrop, DropStatus.delivery_completed);
  };

  const startPickingReceptacles = async (
    tourDrop: TourDrop | null | undefined,
  ) => {
    if (!tourDrop) return;

    await updateDropStatus(tourDrop, DropStatus.picking_receptacles);

    await router.get().push({
      name: 'picking-receptacles',
      params: {
        tourId: route.params.tourId,
        dropId: tourDrop.reference,
      },
    });
  };

  const handleAllPickedUp = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;

    tourDrop.actions = [DropActions.start_handover];
    await updateDropStatus(tourDrop, DropStatus.picking_receptacles_completed);

    if (!currentTour.value) return;
    const dropIndex = drops.value.findIndex(
      (drop) => drop.reference === tourDrop.reference,
    );
    drops.value[dropIndex] = {
      ...tourDrop,
      dropIndex: drops.value[dropIndex]?.dropIndex,
    };
    await redirectToTourDrops();
  };

  const startHandover = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;
    if (!currentTour.value || !currentActiveDrop.value) return;

    if (await timeConditionApplies(tourDrop, 'startHandoverBufferTime')) {
      const { secretToggle } = useDynamicDialog();
      if (
        (await showNotAvailablePopup(tourDrop)) &&
        secretToggle.value === false
      ) {
        return;
      }
    }

    if (
      (await hasToShowNewCustomerPopup(tourDrop)) &&
      !(await showNewCustomerPopup())
    ) {
      return;
    }

    const pickingOrderDependencyActive = await configurationServicePlugin
      .get()
      .getFeatureOption(
        'driverApp',
        'pickingOrdersDependencyActive',
        'boolean',
      );
    let dropOrder;
    try {
      dropOrder = await pickingOrderServicePlugin
        .get()
        .findOrder(currentActiveDrop?.value?.orderReferences[0]);
    } catch (error) {
      if (!pickingOrderDependencyActive) {
        try {
          await pickingOrderServicePlugin.get().reloadOrders();
          dropOrder = await pickingOrderServicePlugin
            .get()
            .findOrder(currentActiveDrop?.value?.orderReferences[0]);
        } catch (error) {
          return errorPlugin.get().handle(error);
        }
      } else {
        return errorPlugin.get().handle(error);
      }
    }
    pickingOrderServicePlugin.get().updateOrderItems(dropOrder);
    await updateDropStatus(tourDrop, DropStatus.handover_in_progress);

    currentActiveDrop.value.orderIds = [dropOrder.id];

    dropOrder.localStatus = OrderLocalStatus.HandoverInProgress;
    const result = await pickingOrderServicePlugin.get().saveOrder(dropOrder);

    if (!result) {
      return errorPlugin
        .get()
        .handle(new Error('Failed to save order. Unable to start handover'));
    }

    await router.get().push({
      name: 'handover-drops',
      params: {
        id: dropOrder.id,
        dropId: currentActiveDrop.value?.reference,
        tourId: +currentTour.value?.id,
      },
    });
  };

  const showCancelDrop = ref(false);
  const showPauseOrRemoveDialog = ref(false);

  const cancelDrop = async () => {
    if (!currentTour.value) return;

    const confirmed = await confirm({
      e2e: 'cancel-drop-confirmation',
      title: $t(
        'components.drops-list.remove-drop-first-confirmation-popup.title.text',
      ),
      confirmText: $t(
        'components.drops-list.remove-drop-first-confirmation-popup.confirm.text',
      ),
      contentText: $t(
        'components.drops-list.remove-drop-first-confirmation-popup.content.text',
      ),
      cancelText: $t(
        'components.drops-list.remove-drop-first-confirmation-popup.cancel.text',
      ),
    });

    if (!confirmed) return;

    isDropCanceled.value = true;
    showPauseOrRemoveDialog.value = false;
    showCancelDrop.value = false;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
      isDropCanceled.value = false;
      await updateDropStatus(currentActiveDrop.value, DropStatus.drop_canceled);

      // It's no longer the current active drop after removing it: there's no current active drop at the moment.
      setCurrentActiveDrop();
    }, 3000);
  };

  const continueHandover = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;
    if (!currentTour.value || !currentActiveDrop.value) return;

    let dropOrder;
    try {
      dropOrder = await pickingOrderServicePlugin
        .get()
        .findOrder(currentActiveDrop?.value?.orderReferences[0]);
    } catch (error) {
      return errorPlugin.get().handle(error);
    }

    await router.get().push({
      name: 'handover-drops',
      params: {
        id: dropOrder.id,
        dropId: currentActiveDrop.value?.reference,
        tourId: +currentTour.value?.id,
      },
    });
  };

  const pauseDrop = (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;
    if (!currentTour.value || !currentActiveDrop.value) return;

    showPauseOrRemoveDialog.value = false;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
      if (!currentActiveDrop.value) return;
      tourDrop.status = DropStatus.drop_paused;
      tourDrop.actions = [DropActions.start_drop_off];
      tourDrop.pauseTime = formatDateTime(new Date());
      await updateDropStatus(tourDrop, DropStatus.drop_paused);

      // After pausing the drop it's no longer the active drop: there's no active drop at the moment.
      setCurrentActiveDrop();
    }, 1000);
  };

  const isBottleDepositNeeded = async (): Promise<boolean> => {
    const isBottleDepositActive = (
      await configurationServicePlugin.get().isFeatureActive('bottleDeposit')
    ).value;

    const hasExchangeableItems =
      (await dropsHandoverServicePlugin.get().getExchangeableOrderItems())
        .length > 0;

    return isBottleDepositActive || hasExchangeableItems;
  };

  const openBottleDeposit = async (): Promise<void> => {
    if (!order.value || !currentTour.value || !currentActiveDrop.value) {
      return;
    }
    await router.get().push({
      name: 'bottle-deposit-handover',
      params: {
        id: order.value.id,
        tourId: currentTour.value.id,
        dropId: currentActiveDrop.value.reference,
      },
    });
  };

  const redirectToHandoverSummary = async () => {
    if (!order.value || !currentTour.value || !currentActiveDrop.value) {
      return;
    }
    await router.get().push({
      name: 'handover-summary',
      params: {
        id: order.value.id,
        tourId: currentTour.value.id,
        dropId: currentActiveDrop.value.reference,
      },
    });
    return;
  };

  const completeHandover = async () => {
    const confirmed = await useDynamicDialog().confirm({
      title: $t(
        'components.drops-list.complete-handover-confirm-dialog.title.text',
      ),
      confirmText: $t(
        'components.drops-list.complete-handover-confirm-dialog.confirm-text.text',
      ),
    });
    if (!confirmed) return;

    if (!order.value) {
      return await router.get().push('/');
    }

    if (!currentActiveDrop.value) {
      if (currentTour.value?.id) {
        return await redirectToTourDrops();
      }

      return await router.get().push('/tours');
    }
    if (!currentActiveDrop.value) return;

    const bottleDepositReturns: BottleDepositReturn[] =
      dropsHandoverServicePlugin.get().getBottleDepositReturns();
    const rejectedDeliveryItems: RejectedDeliveryItem[] =
      dropsHandoverServicePlugin.get().prepareRejectedDeliveryItems();
    const exchangedDeliveryItems: ExchangedItem[] = dropsHandoverServicePlugin
      .get()
      .getExchangedItems();

    const tourDropEntity = {
      ...currentActiveDrop.value,
      rejectedDeliveryItems,
      bottleDepositReturns,
      exchangedDeliveryItems,
    };

    await updateDropStatus(tourDropEntity, DropStatus.handover_completed);

    try {
      await pickingOrderServicePlugin.get().saveOrder(order.value);
    } catch (error) {
      errorPlugin.get().handle(error);
    } finally {
      order.value = null;
      if (currentActiveDrop.value.surveyId) {
        await startSurvey();
      } else {
        await completeDrop();
      }
    }
  };

  const startSurvey = async () => {
    if (currentActiveDrop.value?.surveyId) {
      await updateDropStatus(
        currentActiveDrop.value,
        DropStatus.survey_in_progress,
      );
      await router.get().push({
        name: 'survey',
        params: {
          dropId: currentActiveDrop.value?.reference,
          tourId: currentTour.value?.id,
        },
      });
    }
  };

  const completeDrop = async () => {
    await updateDropStatus(currentActiveDrop.value, DropStatus.drop_completed);
    if (currentActiveDrop.value) {
      addToRecentlyDoneDrops(currentActiveDrop.value);
    }
    await redirectToTourDrops();
  };

  const handleCustomerNotAvailableClicked = async (
    tourDrop: TourDrop,
  ): Promise<void> => {
    if (
      await timeConditionApplies(tourDrop, 'customerNotAvailableBufferTime')
    ) {
      const { secretToggle } = useDynamicDialog();
      if (
        (await showNotAvailablePopup(tourDrop)) &&
        secretToggle.value === false
      ) {
        return;
      }
    }
    showPauseOrRemoveDialog.value = true;
  };

  const timeConditionApplies = async (
    tourDrop: TourDrop,
    configOptionId: string,
  ): Promise<boolean> => {
    if (!tourDrop || !configOptionId) {
      return false;
    }
    const now = useNow();
    const { featureList } = useFeatureToggle();
    const bufferTimeInMinutes: number = await configurationServicePlugin
      .get()
      .getFeatureOption('driverApp', configOptionId, 'number');
    const startTime = new Date(tourDrop.startTime);
    startTime.setMinutes(startTime.getMinutes() - bufferTimeInMinutes);

    return (
      tourDrop.status === DropStatus.picking_receptacles_completed &&
      startTime > now.value &&
      !featureList.value.disableTimeDependentCTAs.active
    );
  };

  const showNotAvailablePopup = async (tourDrop: TourDrop) => {
    const { confirm, secretToggle, onConfirm } = useDynamicDialog();

    watch(secretToggle, (newValue: boolean) => {
      if (newValue) {
        onConfirm();
      }
    });

    return await confirm({
      title: $t(
        'components.drops-list-item.start-time-not-reached-dialog.title.text',
      ),
      isTitleCentered: false,
      contentText: $t(
        'components.drops-list-item.start-time-not-reached-dialog.content.text',
        {
          startTime: formatHours(new Date(tourDrop.startTime), true, true),
        },
      ),
      confirmText: $t(
        'components.drops-list-item.start-time-not-reached-dialog.confirm.text',
      ),
      showOnlyConfirm: true,
      e2e: 'start-time-not-reached-dialog',
    });
  };

  const applyAction = async (
    action: string,
    tourDrop: TourDrop | null | undefined = null,
  ) => {
    processing.value = true;
    if (!tourDrop) {
      tourDrop = currentActiveDrop.value;
    }
    setCurrentActiveDrop(tourDrop);

    switch (action) {
      case DropActions.start_drop_off:
        await startDropOff(tourDrop);
        break;
      case DropActions.confirm_arrival:
        await confirmArrival(tourDrop);
        break;
      case DropActions.start_picking_receptacles:
        await startPickingReceptacles(tourDrop);
        break;
      case DropActions.all_picked_up:
        await handleAllPickedUp(tourDrop);
        break;
      case DropActions.start_handover:
        await startHandover(tourDrop);
        break;
      case DropActions.continue_handover:
        await continueHandover(tourDrop);
        break;
      case DropActions.cancel_drop:
        await cancelDrop();
        break;
      case DropActions.pause_drop:
        pauseDrop(tourDrop);
        break;
      case DropActions.complete_deposit_goods:
        await redirectToHandoverSummary();
        break;
      case DropActions.complete_review_summary:
        await completeHandover();
        break;
      case DropActions.start_survey:
        await startSurvey();
        break;
      case DropActions.complete_drop:
        await completeDrop();
        break;
      default:
        break;
    }
    processing.value = false;
  };

  const isDropInNewerStatus = (tourDrop: TourDrop, dropStatus: DropStatus) => {
    if (
      dropStatus === DropStatus.delivery_in_progress ||
      tourDrop.status === DropStatus.drop_paused
    ) {
      return false;
    }
    return (
      dropStatusOrder.findIndex(
        (status: DropStatus) => status === dropStatus,
      ) <=
      dropStatusOrder.findIndex(
        (status: DropStatus) => tourDrop.status === status,
      )
    );
  };

  const updateDropStatus = async (
    tourDrop: TourDrop | undefined | null,
    dropStatus: DropStatus,
  ) => {
    if (!tourDrop) return;
    if (isDropInNewerStatus(tourDrop, dropStatus ?? tourDrop.status)) return;

    if (dropStatus) {
      tourDrop.status = dropStatus;
    }
    currentActiveDrop.value = tourDrop;

    try {
      await dropsServicePlugin.get().patchDrop(tourDrop);
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  };

  const hasToShowNewCustomerPopup = async (
    tourDrop: TourDrop,
  ): Promise<boolean> => {
    const newCostumerModal = await configurationServicePlugin
      .get()
      .getFeatureOption('driverApp', 'newCustomerModal', 'string');
    return (
      tourDrop.newCustomer &&
      newCostumerModal !== 'false' &&
      newCostumerModal.length > 0
    );
  };

  const showNewCustomerPopup = async () => {
    const { confirm } = useDynamicDialog();
    return await confirm({
      title: $t('pages.handover-order.new-customer-popup.headline.text'),
      showOnlyConfirm: true,
      confirmText: $t('pages.handover-order.new-customer-popup.confirm.text'),
      isTitleCentered: false,
      component: {
        component: NewCustomerPopupContent,
        props: {},
      },
      e2e: 'new-customer-modal',
    });
  };

  const disablePausedDrops = computed(() =>
    availableDrops.value.some((drop) => drop.status !== DropStatus.open),
  );

  return {
    drops,
    loadDrops,
    availableDrops,
    activeDrops,
    completedDrops,
    currentActiveDrop,
    recentlyDoneDrops,
    applyAction,
    pausedDrops,
    setCurrentActiveDrop,
    isDropCanceled,
    addToRecentlyDoneDrops,
    resetRecentlyDoneDrops,
    showPauseOrRemoveDialog,
    handleCustomerNotAvailableClicked,
    disablePausedDrops,
    processing,
    redirectToTourDrops,
    isBottleDepositNeeded,
    openBottleDeposit,
  };
}
