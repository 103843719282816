import { errorPlugin } from '@/features/core/errors';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { useTours } from '@/features/tours';
import { useDrops } from '../composables';
import { DropsService } from './drops-services';
import { PickingReceptaclesService } from './picking-receptacles-service';
import { DropsHandoverService } from './drops-handover-service';
import { useHandoverOrder } from '@/features/handover/composables';
import { useOrders } from '@/common/composables';
import { DropsSortingService } from './drops-sorting-service';
import { loggerServicePlugin } from '@/features/core/logger';
import { DropStatusToActionMiddlemileService } from './drop-status-to-action-middlemile-service';
import { PickingOrderService } from './picking-order-service';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { ordersServicePlugin } from '@/features/orders';
import { storagePlugin } from '@/features/core/storage';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { useDeposit } from '@/features/handover/composables';

export * from './drops-services';
export * from './picking-receptacles-service';

export const dropsServicePlugin = ProviderPluginFactory.create<DropsService>({
  key: Symbol('TourDropsService'),
  defaultFactory: {
    create: () => {
      return new DropsService();
    },
  },
});

export const pickingReceptaclesServicePlugin =
  ProviderPluginFactory.create<PickingReceptaclesService>({
    key: Symbol('PickingReceptaclesService'),
    defaultFactory: {
      create: () =>
        new PickingReceptaclesService(
          useDrops().currentActiveDrop,
          useTours(),
          errorPlugin.get(),
        ),
    },
  });

export const dropsHandoverServicePlugin =
  ProviderPluginFactory.create<DropsHandoverService>({
    key: Symbol('TourDropsHandoverService'),
    defaultFactory: {
      create: () => {
        const { getDepositOptions, getDepositOptionFromOrder } = useDeposit();
        return new DropsHandoverService(
          useTours().currentTour,
          useHandoverOrder(),
          errorPlugin.get(),
          useDrops().currentActiveDrop,
          useOrders(),
          getDepositOptions,
          getDepositOptionFromOrder,
        );
      },
    },
  });

export const dropStatusToActionMiddlewareServicePlugin =
  ProviderPluginFactory.create<DropStatusToActionMiddlemileService>({
    key: Symbol('DropStatusToActionMiddlemileService'),
    defaultFactory: {
      create: () => {
        return new DropStatusToActionMiddlemileService();
      },
    },
  });

export const dropsSortingServicePlugin =
  ProviderPluginFactory.create<DropsSortingService>({
    key: Symbol('TourDropsSortingService'),
    defaultFactory: {
      create: () => new DropsSortingService(loggerServicePlugin.get()),
    },
  });

export const pickingOrderServicePlugin =
  ProviderPluginFactory.create<PickingOrderService>({
    key: Symbol('PickingOrderService'),
    defaultFactory: {
      create: () =>
        new PickingOrderService(
          ordersServicePlugin.get(),
          syncSchedulerServicePlugin.get(),
          storagePlugin.get(),
          entityRepositoryPlugin.get(),
        ),
    },
  });
